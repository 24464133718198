export enum TRACKING_GROUP {
  REGISTRATION_TRACKING = 'registration_tracking',
}

export type TRACKING_GROUP_TYPE =
  | TRACKING_GROUP.REGISTRATION_TRACKING;

export default function useTracking () {
  const dispatchTrackingEvent = (
    eventName: string,
    eventProperties: Record<string, unknown> = {},
    trackingGroup?: TRACKING_GROUP_TYPE | undefined,
  ) => {
    if (trackingGroup) {
      const localStorageItem = localStorage.getItem(trackingGroup);
      const items = localStorageItem ? JSON.parse(localStorageItem) : {};

      if (!items[eventName]) {
        items[eventName] = eventProperties;
        localStorage.setItem(trackingGroup, JSON.stringify(items));
      } else {
        return;
      }
    }

    useNuxtApp().$gtmPush({
      event: eventName,
      ...eventProperties,
    });
  };

  const hashEmailAndPushOnBlur = async (event: FocusEvent) => {
    const target = event.target as HTMLInputElement;
    const value = target.value.trim();

    if (!value || value.length === 0) {
      return;
    }

    await hashEmailAndPush(value);
  };

  const hashEmailAndPush = async (email: string) => {
    try {
      const msgUint8 = new TextEncoder().encode(email);
      const hashBuffer = await window.crypto.subtle.digest('SHA-256', msgUint8);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

      useNuxtApp().$gtmPush({
        'event': 'ehcl_email',
        'sha256EmailAddress': hashHex,
      });

    } catch (error) {
      console.error(error);
    }
  };

  const clearGroupTracking = (trackingGroup: TRACKING_GROUP_TYPE) => {
    localStorage.removeItem(trackingGroup);
  };

  return {
    dispatchTrackingEvent,
    clearGroupTracking,
    hashEmailAndPush,
    hashEmailAndPushOnBlur,
  };
}
